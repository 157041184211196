import { Capacitor } from "@capacitor/core";
import ComputeFunctionHelperObj from "@helpers/computeFunctionHelper";
import { getStudentFromStorage } from "@helpers/migrateLogin";
import { v4 as uuid } from "uuid";

function openDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("logDB", 1);
        request.onupgradeneeded = (event) => {
            const db = event?.target?.result;
            if (!db.objectStoreNames.contains("logs")) {
                db.createObjectStore("logs", { autoIncrement: true });
            }
        };
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

function addLog(log) {
    return openDB().then((db) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction("logs", "readwrite");
            const store = transaction.objectStore("logs");
            const request = store.add(JSON.stringify(log));
            request.onsuccess = () => resolve(null);
            request.onerror = () => reject(request.error);
        });
    });
}

class LoggedSession {
    logged_session_id = null;
}
export const loggedSession = new LoggedSession();

export const dumpLogs = async (input: any, dump_name = `mathai`) => {
    try {
        const { mobile, id: student_id } = JSON.parse(
            getStudentFromStorage() || "{}",
        );

        const pathnameArr = window.location.pathname.split("/");
        const now = new Date();
        const logged_at = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}:${now.getMilliseconds()}`;
        const logged_session_id = loggedSession.logged_session_id;
        const error = new Error();
        if (!logged_session_id) return;

        const data = {
            student_id,
            mobile,
            worksheet_id: pathnameArr[pathnameArr.length - 2],
            block_id: ComputeFunctionHelperObj.getWorksheetId(),
            logged_at,
            logged_session_id,
            stack: error?.stack,
            platform: Capacitor.getPlatform(),
            href: window.location.href,
            uuid: uuid(),

            ...input,
        };

        if (String(process.env.NEXT_PUBLIC_DUMP_LOGS) != "true") {
            // console.log("🚀 ~ dumpLogs", data.action, data.sound_name || data.sound_url)
            return;
        }
        addLog({ dump_name, data });
    } catch (error) {
        const { mobile, id: student_id } = JSON.parse(
            getStudentFromStorage() || "{}",
        );
        addLog({
            dump_name,
            data: { error, student_id, mobile, uuid: uuid(), ...input },
        });
    }
};
